<template>
  <a-modal
    width="650px"
    class="find-calendar-modal"
    :dialog-style="{ display: 'none' }"
    :footer="null"
    :closable="true"
    :visible="true"
    @cancel="onExit">
    <h3 data-cy="find-calendar-for-country-header" class="flex align-center txt-32 txt-bold">
      {{ $t('components.titles.findCalendarForCountry', { country: metadata.geographicCountry }) }}
    </h3>
    <a-alert
      :message="$t('components.notifications.note')"
      class="mb-20"
      :description="$t('components.notifications.localEventCalendarUploadedByOther')"
      type="info" show-icon/>
    <a-row>
      <a-col :md="24" :lg="24">
        <a-empty v-if="noResults">
          <span slot="description">
            {{ $t('components.description.noPreviousCalendars') }}
          </span>
        </a-empty>
         <a-list
          v-if="!noResults"
          item-layout="horizontal"
          class="calendar-list"
          :data-source="calendars">
          <a-list-item slot="renderItem" slot-scope="item">
            <a slot="actions" :href="item.url" target="_blank" class="pointer">
              <embed width="30px" src="/icon-cloud-download.svg" class="mr-10 pointer">
            </a>
            <div class="ml-20">{{ item.name }}</div>
          </a-list-item>
        </a-list>
      </a-col>
    </a-row>
    <div class="flex justify-space-between w100">
      <a-button
        class="mt-24 w170"
        type="primary" ghost
        size="large"
        @click="onExit">
        {{ $t('values.cancel') }}
      </a-button>
      <a-button
        class="mt-24 w170"
        type="primary"
        size="large"
        @click="onExit">
        {{ $t('values.done') }}
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import Vue from 'vue';
import { configForPossibleBackendRequest } from '../../../../../util/request';

export default {
  name: 'FindCalendarModal',
  props: {
    onExit: {
      type: Function,
      required: true
    },
    metadata: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      noResults: false,
      calendars: []
    };
  },
  mounted() {
    this.getCalendars();
  },
  methods: {
    async getCalendars() {
      this.noResults = false;
      const ageGroup = this.metadata.includedAgeGroup || '6-59';
      const baseConfig = { method: 'GET', url: `/calendars/${this.metadata.geographicCountry}/${ageGroup}` };
      const token = this.$store.getters.token ? this.$store.getters.token : null;
      const axiosConfig = configForPossibleBackendRequest(baseConfig, token);
      try {
        const { data } = await Vue.prototype.$http.request(axiosConfig);
        if (data) {
          if (data.length > 0) {
            this.calendars = data;
          } else {
            this.noResults = true;
          }
        }
      } catch (error) {
        this.error = error.response && error.response.data.message;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .calendar-list {
    max-height: 30vh;
    overflow: scroll;
    border: 1px solid #e8e8e8;
  }
</style>
